body {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: #3f4850;
}

h2 {
  font-size: 2em;
  margin: 52px 0 40px;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}

.search-form {
  max-width: 460px;
  display: flex;
  margin: 0 auto 32px;
}

.search-form button {
  outline: none;
  background-color: #438bbd;
  border: none;
  padding: 0px 15px;
  border-radius: 0 0.35em 0.35em 0;
  cursor: pointer;
}

input {
  font-size: 1em;
  width: 100%;
  background-color: #edeff0;
  padding: 10px 15px;
  border: 3px solid #d7dbdf;
  border-right: none;
  border-radius: 0.35em 0 0 0.35em;
  outline: none;
}

.main-nav li {
  width: 100%;
  margin-bottom: 1em;
}

.main-nav a {
  display: block;
  background: #438bbd;
  border-radius: 3px;
  padding: 5px;
  color: #fff;
}

.main-nav .active,
.main-nav a:hover,
.search-form button:hover {
  background-color: #275270;
}

.photo-container ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  -ms-align-items: flex-start;
  align-items: flex-start;
}

.photo-container ul li {
  width: 220px;
  height: 165px;
  overflow: hidden;
  margin-bottom: 15px;
}

.photo-container ul img {
  width: 100%;
  transform: scale(1.25);
  transition: transform 1.25s;
}

.photo-container li:hover img {
  transform: scale(1.65);
}

.photo-container .not-found {
  width: 100%;
}

@media only screen and (max-width: 767px)  {
  body {
    padding-top: 2em;
  }

  .photo-container li {
    margin: auto;
  }
}

@media only screen and (min-width: 768px)  {
  body {
    padding-top: 4em;
  }

  .container {
    max-width: 960px;
    margin: auto;
  }

  .main-nav ul {
    display: flex;
    justify-content: center;
  }
  
  .main-nav li {
    margin: 10px;
    width: 100px;
  }

  /* Customized CSS */
  /* add box shadow and hover effect */
  .photo-thumbnail {
    box-shadow: 8px 8px 5px 0px rgba(0,0,0,0.75);
    transition: 0.2s ease-in-out;
  }

  .photo-thumbnail:hover {
    transform: scale(1.05);
  }

  /* cursor blinking animation for title */
  .title-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .title {
    font-family: monospace;
    letter-spacing: 0.15em;
    overflow: hidden;
    white-space: nowrap;
    border-right: 0.15em solid #438bbd;
    animation: 
      typing 1s steps(20, start) forwards,
      blinking-cursor 0.5s step-end infinite;
  }

  @keyframes typing {
    from {
      width: 0
    }
    to {
      width: 40%
    }
  }

  @keyframes blinking-cursor {
    0% {
      border-color: transparent
    }

    50% {
      border-color: #438bbd;
    }
  }
}
